import { defineStore } from "pinia";

const gaeaData = defineStore("gaeaData", {
  state: () => ({
    // gaea球是否加载完成
    gaeaIsLoding: false,
    // 图层列表
    layersList: [],
    // 3dtil列表
    tilsList: [],
    // gltf列表
    gltfList: [],
    // 公告牌列表
    billBoardList: [],
    // 河流对象
    riverList: [],
    // 实时数据
    realTimeData: {
      s: 0, // 含沙
      v: 0, // 流速
      lives: 0, // 天气
      z: 0, // 水位
      r: 0, // 雨量
      t: 0, // 工况
    },
    // 面片弹窗
    billBoardDialogData: {
      show: false,
      type: "",
      dialogConfig: {},
    },
    // 滚轮力度
    rollerPower: 0,

    // 屏幕标记
    ScreenIconList: [],

    // 地标身份识别
    markingIdentification: '',

    // 移动鼠标时的经纬度坐标
    MoveCoordinates: {},

    // 编辑工具类型
    editToolType: '',
    // 画线模型
    drawLineModel: {
      line: {
        allLine: [],// 所有线
        boundaryLine: [], // 边界线
        sectionalLine: []  // 断面线
      },
      point: [],
      polygon: {
        allPolygon: []
      }
    },

    // 请求资源list
    resourcesList: {},
    // 鼠标当前点击的坐标点
    clickPoint: {},
    // three配置
    
  }),
  actions: {
    //gaea球加载完成
    changeGaeaLoding() {
      this.gaeaIsLoding = true;
    },
    // 添加图层
    async addLayer(layerObj) {
      const layer = await APP.addLayer(layerObj);

      this.layersList.push({
        layer,
      });
    },
    // 添加3dtils
    async add3dtils(tilsObj) {
      const tils = await APP.add3dtils(tilsObj);

      this.tilsList.push({
        tilName: tilsObj.name,
        show: true,
        tils,
      });
    },
    // 添加gltf模型
    addGltf(gltfObj, cb) {
      return new Promise(async (res, rej) => {
        const gltf = await APP.AddGltf(gltfObj);

        this.gltfList.push({
          ...gltfObj,
          element: gltf,
        });

        if (gltfObj?.initFun) {
          gltfObj.initFun();
        }

        res(gltf);
      });
    },
    // 添加面片
    async addBillBoard(billBoardObj) {
      const billBoard = await APP.addBillBoard(billBoardObj);

      this.billBoardList.push({
        ...billBoardObj,
        element: billBoard,
      });
    },

    // 添加屏幕标记
    async addScreenIcon(ScreenIconObj) {
      return new Promise(async (res, rej) => {
        const ScreenIcon = await APP.addScreenIcon(ScreenIconObj);

        this.ScreenIconList.push({
          ...ScreenIconObj,
          element: ScreenIcon,
        });

        res(ScreenIcon);
      })
    },

    /**
     * 显示隐藏屏幕标记
     * 
     */
    async showScreenIcon(bool) {
      if (bool) {
        this.ScreenIconList.forEach((item) => {
          item.element.UseAutoVisible();
          item.element.EnablePick = bool;
        });
      } else {
        this.ScreenIconList.forEach((item) => {
          item.element.ForceSetVisible(bool);
          item.element.EnablePick = bool;
        });
      }
    }
    ,
    /**
     * @description: 添加标注点
     * @return {*}
     */
    async addSymbol(iconData) {
      const symbol = APP.AddSymbol(iconData);

      this.symbolList.push({
        name: iconData.name,
        symbol,
      });
    },
    async addGuiSymbol(guiSymbolObj) {
      const guiSymbol = APP.addGuiSymbol(guiSymbolObj);

      this.guiSymbolList.push({
        ...guiSymbolObj,
        element: guiSymbol,
      });
    },

    /**
     * @description: 添加标注点
     * @param {*} riverOBj 河流对象
     * @return {*}
     */
    async addWaterHandle(riverOBj) {
      const river = await APP.addWaterHandle(riverOBj);

      this.riverList.push({
        ...riverOBj,
        ...river,
      });
    },

    /**
     * @description: 面片显示切换
     * @param {*} billBoardName 需要切换的面片名称
     * @return {*}
     */
    billBoardShowChange(billBoardName) {
      // 从当前面片数组中取出需要操作的面片
      const billBoardItem = this.billBoardList.find(
        (item) => item.name === billBoardName
      );

      // show属性取反
      billBoardItem.show = !billBoardItem.show;

      // 调用gaeaMethods方法 切换显示状态
      APP.showTypeChange(
        billBoardItem.billBoard.InstanceID,
        billBoardItem.show
      );
    },

    /**
     * @description: 弹窗展示
     * @param {*} show 是否展示
     * @param {*} type 弹窗类型
     * @param {*} dialogConfig 弹窗配置参数
     * @return {*}
     */
    billBoardDialogShowTypeChange(show, type = "", dialogConfig = {}) {
      this.billBoardDialogData = {
        show,
        type,
        dialogConfig,
      };
    },

    // 移除gltf模型
    removeGltf(gltfName) {
      let index = this.gltfList.findIndex((item) => {
        return item.gltfName === gltfName;
      });

      let gltfData = this.gltfList[index].gltfData;
      gltfData.forEach((item) => {
        APP.removeGltf(item);
      });
      this.tilsList.splice(index, 1);
    },
    // 移除公告牌
    removeBillBoard(billBoardName) {
      let index = this.billBoardList.findIndex((item) => {
        return item.billBoardName === billBoardName;
      });

      let billBoard = this.billBoardList[index].billBoardData;
      APP.removeBillBoard(billBoard);
      this.billBoardList.splice(index, 1);
    },
    //移除某个3dtils
    remove3dtils(tilName) {
      let index = this.tilsList.findIndex((item) => {
        return item.tilName === tilName;
      });

      let tils = this.tilsList[index].tilData;
      APP.remove3dtils(tils);
      this.layersList.splice(index, 1);
    },
    //移除图层
    removeLayer(layerName) {
      let index = this.layersList.findIndex((item) => {
        return item.Name === layerName;
      });

      this.layersList.splice(index, 1);
      APP.removeLayer(layerName);
    },
  },
});

export default gaeaData;
