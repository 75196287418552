import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted, onUnmounted } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    onMounted(() => {
      // 只在VUE_APP_DEBUG_TOOLS开启
      if (process.env.VUE_APP_DEBUG_TOOLS == true) {
        // 2.禁止键盘F12键
        document.addEventListener("keydown", function (e) {
          if (e.key == "F12") {
            e.preventDefault();
          }
        });
        // 3.禁止进入调试模式
        setInterval(function () {
          check();
        }, 1000);
        var check = function () {
          function doCheck(a) {
            if (("" + a / a)["length"] !== 1 || a % 20 === 0) {
              (function () {})["constructor"]("debugger")();
            } else {
              (function () {})["constructor"]("debugger")();
            }
            doCheck(++a);
          }
          try {
            doCheck(0);
          } catch (err) {}
        };
      }

      // 写死沙市token
      localStorage.setItem("shashiToken", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJNSU5HIiwiaWF0IjoxNzIxNzE1MzUzLCJ1c2VySWQiOjE0MTk4NDQ0MDcxNTc4NzQ2OTB9.M3TAWooLhPxnS22D3YilWdvtOUkQRokmJy5E0clREfs");
    });
    onUnmounted(() => {});
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};