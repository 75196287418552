import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f937807c"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["xlink:href"];
export default {
  __name: 'index',
  props: {
    dir: {
      type: String,
      default: ""
    },
    // svg 图标在assets/svg/下的目录
    name: {
      type: String,
      required: true
    },
    // svg 图标名称
    className: {
      type: String,
      default: ""
    },
    // 指定的类样式
    size: {
      type: Number,
      default: 32
    },
    // 图标尺寸
    heightSize: {
      type: Number
    },
    // 图标尺寸
    color: {
      type: String,
      default: "#000"
    } // 图标颜色
  },
  setup(__props) {
    const props = __props;
    let path = "";
    if (props.dir) {
      path = `${props.dir + "/" + props.name}`;
    } else {
      path = props.name;
    }
    import(`@/assets/svg/${path}.svg`);
    const svgClass = computed(() => {
      if (props.className) {
        return "svg-icon " + props.className;
      } else {
        return "svg-icon";
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("svg", {
        class: _normalizeClass(_unref(svgClass)),
        style: _normalizeStyle({
          width: __props.size + 'px',
          height: (__props.heightSize || __props.size) + 'px',
          color: __props.color
        }),
        "aria-hidden": "true"
      }, [_createElementVNode("use", {
        "xlink:href": `#icon-${__props.name}`
      }, null, 8, _hoisted_1)], 6);
    };
  }
};