function getPXforAdapt(pxValue) {
  const rem = Number((pxValue / 192).toFixed(5)),
    rootFontSize = Number(
      document.documentElement.style.fontSize.replace("px", "")
    );

  return rem * rootFontSize;
}

export default getPXforAdapt;
