const DISTANCE = 10
const DURATION = 500
const map = new WeakMap()
const ob = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const animation = map.get(entry.target)
      animation && animation.play()
      ob.unobserve(entry.target)
    }
  })
})
function isBelowViewport(el) {
  const rect = el.getBoundingClientRect()
  return rect.top - window.innerHeight > 0
}
export default {
  mounted(el) {
    // 判断元素是否在视口内
    if (!isBelowViewport(el)) {
      return
    }
    const animation = el.animate(
      [{ transform: `scale(${0})`, opacity: 0.5 }, { transform: `scale(${DISTANCE})`, opacity: 1 }],
      {
        duration: DURATION,
        easing: 'ease-out',
        fill: 'forwards'
      }
    )
    animation.pause()
    map.set(el, animation)
    ob.observe(el)
  },
  unmounted(el) {
    ob.unobserve(el)
  },
}
