/*
 * @Descripttion:
 * @version:
 * @Author: xcb
 * @Date: 2023-03-06 09:21:00
 * @LastEditors: yangyongtao
 * @LastEditTime: 2024-04-10 09:25:40
 */
import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";

export const constantRoutes = [
  {
    path: "/",
    redirect: '/gaea'
  },
  {
    path: '/gaea',
    name: "gaea",
    component: () => import("@/views/gaea/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
});

router.beforeEach((to, from, next) => {
  // 登陆状态判断
  if (to.path === "/gaea") {
    next();
  }
  next()
});

export default router;
